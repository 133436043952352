import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { routing, appRoutingProviders } from './app.routing';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ReserveComponent } from './reserve/reserve.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

import { MatCarouselModule } from '@ngmodule/material-carousel';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { MatTabsModule } from '@angular/material/tabs';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatGridListModule } from '@angular/material/grid-list';

import { Angular2ImageGalleryModule } from 'angular2-image-gallery';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { MatInputModule } from '@angular/material/input';

import { MatStepperModule } from '@angular/material/stepper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ModelSurroundingsComponent } from './model-surroundings/model-surroundings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    RoomsComponent,
    ReserveComponent,
    AboutComponent,
    ScrollToTopComponent,
    ModelSurroundingsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    routing,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MatTabsModule,
    FlexLayoutModule,
    MatGridListModule,
    Angular2ImageGalleryModule,
    MatCardModule, MatButtonModule,
    MatInputModule,
    MatStepperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }
    ),
    NgbModule
  ],
  providers: [
    appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
