import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedModuleModule } from './shared-module/shared-module.module';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Amor Eterno Hotel';
  lang = this.translate.getBrowserLang();


  constructor(private translate: TranslateService) {
    this.setAppLanguage();
  }

  setAppLanguage() {
    this.translate.setDefaultLang(this.lang);
    // this.translate.use(this.translate.getBrowserLang());
  }

  onclick() {


    this.translate.setDefaultLang(this.lang);
  }


  func(lang: string) {
    this.lang = lang;

    this.translate.setDefaultLang(lang);

  }



}
