import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements AfterViewInit, OnInit, MatTabsModule {

  slide = 0;
  loading = false; loading2 = false; loading3 = false;


  constructor() { }

  ngOnInit(): void {

  }


  tabChanged(tabChangeEvent: MatTabChangeEvent): void {

    this.loading = false; this.loading2 = false; this.loading3 = false;
    switch (tabChangeEvent.index) {


      case 1:
        setTimeout(() => {
          this.loading = true;
        }, 0);
        break;
      case 2:
        setTimeout(() => {

          this.loading2 = true;
        }, 0);
        break;
      case 3:
        setTimeout(() => {
          this.loading3 = true;
        }, 0);
        break;
    }
  }



  onViewerVisibilityChanged(isVisible: boolean): void {
    console.log(`viewer visible: ${isVisible}`)



  }

  ngAfterViewInit() {



  }



}
