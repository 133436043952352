import { Component, OnInit } from '@angular/core';


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {


  closeResult: string;

  title: string;
  description: string;
  mapa: any;


  constructor(private modalService: NgbModal,
    private sanitizer: DomSanitizer) {

  }


  ngOnInit(): void {
  }

  openXl(content, cont) {


    switch (cont) {
      case "islands": {
        this.title = "Lago de Páztcuaro y sus Islas"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d30081.916565985928!2d-101.62904126919052!3d19.53132562836839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842d95df4c874881%3A0xa07605ebe94dfd4e!2sEmbarcadero%2C%20Patzcuaro%20-%20Janitzio%2C%2061609%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5430322!2d-101.6169278!5e0!3m2!1sen!2smx!4v1635756100347!5m2!1sen!2smx");

        break;
      }
      case "tzintzuntzan": {
        this.title = "Tzintzuntzan e Ihuatzio"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d60149.90824593758!2d-101.63035419606338!3d19.568674472539552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842dbd1b86a6ca51%3A0x2b411c109ee49265!2sTzintzuntzan%2C%20Michoac%C3%A1n!3m2!1d19.6287856!2d-101.57768999999999!5e0!3m2!1ses-419!2smx!4v1635732360338!5m2!1ses-419!2smx");
        break;
      }
      case "quiroga": {
        this.title = "Quiroga"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d120292.31522779558!2d-101.58799249816998!3d19.57872258392297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842da352d220e90f%3A0xafb5cc984f361023!2sQuiroga%2C%20Michoac%C3%A1n!3m2!1d19.6648009!2d-101.52327659999999!5e0!3m2!1ses-419!2smx!4v1635732545736!5m2!1ses-419!2smx");

        break;
      }
      case "santaClara": {
        this.title = "Santa Clara del Cobre"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d60190.666855002004!2d-101.64898394642617!3d19.459158888430462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842d8c6081cba8d7%3A0x7e7d2832d0b54069!2sSanta%20Clara%20del%20Cobre%2C%20Michoac%C3%A1n!3m2!1d19.4064279!2d-101.6396979!5e0!3m2!1ses-419!2smx!4v1635732607826!5m2!1ses-419!2smx");
        break;
      }
      case "tacambaro": {
        this.title = "Tacámbaro"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d240896.49974082486!2d-101.67543616769959!3d19.368814570235422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842d876a1ab903eb%3A0xb14020defc2ad5de!2sTacambaro%2C%20Michoac%C3%A1n!3m2!1d19.225808999999998!2d-101.457397!5e0!3m2!1ses-419!2smx!4v1635732668741!5m2!1ses-419!2smx");
        break;
      }
      case "zirahuen": {
        this.title = "Zirahuen"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d60176.55816057295!2d-101.68888504630058!3d19.497134882914487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842dec966ab2c12d%3A0x104edbd9e38a5724!2sZirahu%C3%A9n%2C%20Mich.!3m2!1d19.4551023!2d-101.7310286!5e0!3m2!1ses-419!2smx!4v1635732749053!5m2!1ses-419!2smx");
        break;
      }
      case "uruapan": {
        this.title = "Uruapan"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d120371.30336727548!2d-101.8974960996066!3d19.472666245459912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842de258c2fe4851%3A0x537e9be21f239f34!2sUruapan%2C%20Michoac%C3%A1n!3m2!1d19.4064492!2d-102.0430476!5e0!3m2!1ses-419!2smx!4v1635732791180!5m2!1ses-419!2smx");
        break;
      }
      case "morelia": {
        this.title = "Morelia"
        this.description = cont;
        this.mapa = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d120276.91204822791!2d-101.47442149788981!3d19.599339971981642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!3m2!1d19.5109071!2d-101.6111011!4m5!1s0x842d0ba2b29da7e3%3A0x4016978679c8620!2sMorelia%2C%20Michoac%C3%A1n!3m2!1d19.7059504!2d-101.1949825!5e0!3m2!1ses-419!2smx!4v1635732441688!5m2!1ses-419!2smx");
        break;
      }
      default: {
        //statements; 
        break;
      }

    }


    this.modalService.open(content, { size: 'lg', centered: true });
  }

}