<div class="row contact">
  <div class="col-sm-4">
    <h1 class="font-cursive">{{"contact.title"|translate}}</h1>
    <p> <i class="fas fa-map-marker-alt"></i> {{"footer.address"|translate}}</p>
    <p> <i class="fas fa-phone"></i> +52 33 3302 0465</p>
    <p>
      <i class="fas fa-envelope"></i>
      <a href="mailto:aepatzcuaro@gmail.com"> aepatzcuaro@gmail.com</a>
    </p>

    <div class="social">
      <a target="_blank" href="http://www.facebook.com/aepatzcuaro">
        <i class="fab fa-facebook-square"></i>
      </a>
      <a target="_blank" href="http://www.instragram.com/aepatzcuaro">
        <i class="fab fa-instagram"></i>
      </a>
    </div>
  </div>

  <form id="contactForm" class="col-sm-6">
    <div class="row">
      <div class="col-lg-6 col-sm-10">
        <div class="form-group">
          <input type="text" name="name" id="name" placeholder="{{'contact.mail.name'|translate}}"
            class="form-control" />
        </div>
      </div>
      <div class="col-lg-6 col-sm-10">
        <div class="form-group">
          <input type="email" name="email" id="email" placeholder="{{'contact.mail.email'|translate}}"
            class="form-control" />
        </div>
      </div>
      <div class="col-lg-6 col-sm-10">
        <div class="form-group">
          <input type="text" name="phone_number" id="phone_number" placeholder="{{'contact.mail.phone'|translate}}"
            class="form-control" />
        </div>
      </div>
      <div class="col-lg-6 col-sm-10">
        <div class="form-group">
          <input type="text" name="msg_subject" id="msg_subject" placeholder="{{'contact.mail.subject'|translate}}"
            class="form-control" />
        </div>
      </div>
      <div class="col-10">
        <div class="form-group">
          <textarea name="message" id="message" cols="30" rows="5" placeholder="{{'contact.mail.message'|translate}}"
            class="form-control"></textarea>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <button type="submit" class="default-btn">
          <span>{{"contact.mail.sendButton"|translate}}</span>
        </button>
      </div>
    </div>
  </form>
</div>

<div class="map-area">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1880.3573152451866!2d-101.61219544212442!3d19.51090962490293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d969c400311e5%3A0x44bbe7845e61328!2sRomero%2034%2C%20Centro%2C%2061600%20P%C3%A1tzcuaro%2C%20Mich.!5e0!3m2!1ses!2smx!4v1618102381954!5m2!1ses!2smx"></iframe>
</div>
