<mat-tab-group mat-align-tabs="center" [selectedIndex]="slide" animationduration="1000ms"
  (selectedTabChange)="tabChanged($event)">
  <mat-tab label="{{'rooms.menuAllRooms'|translate}}">
    <div class="container">
      <div class="box-container row ">
        <div class="image-box">
          <img class="img-fluid rounded d-block m-l-none" src="assets/img/room1/002-14.jpg" alt="" />
        </div>
        <div class="content-box">
          <div class=" wrapper centered ">

            <h2 class="font-cursive">Acá entre nos</h2>
            <a [routerLink]="" (click)="slide = 1" class="explore-btn">{{'rooms.exploreButton'|translate}}</a>
          </div>
        </div>
      </div>

      <div class=" box-container row ">

        <div class="content-box">
          <div class=" wrapper centered">
            <h2 class="font-cursive">Si nos dejan</h2>
            <a [routerLink]="" (click)="slide = 2" class="explore-btn">{{'rooms.exploreButton'|translate}}</a>
          </div>

        </div>
        <div class=" image-box">
          <img class="img-fluid rounded d-block m-l-none" src="assets/img/room2/004-111.jpg" alt="" />
        </div>
      </div>


      <div class="box-container row ">
        <div class="image-box">
          <img class="img-fluid rounded d-block m-l-none" src="assets/img/room3/001-235.jpg" alt="" />
        </div>

        <div class="content-box">
          <div class=" wrapper centered">
            <h2 class="font-cursive">Sabor a mí</h2>
            <a [routerLink]="" (click)="slide = 3" class="explore-btn">{{'rooms.exploreButton'|translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Acá entre nos">
    <section>
      <!--
        id="gallery" class="photo-gallery-area pt-100"
        <div class="container">
        <div class="section-title">
            <h2>Room #1: Acá entre nos</h2>
            <p></p>
        </div>
        </div>
                                                                                                                                                -->
      <gallery *ngIf="loading" [flexBorderSize]="20" [flexImageSize]="7" [galleryName]="'galleryRoom1'"
        [maxRowsPerPage]="50" (viewerChange)="onViewerVisibilityChanged($event)"></gallery>


      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="about-content">
            <h3>{{'rooms.description'|translate}}:</h3>
            <p></p>
            <div class=" about-inner">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-money-bill"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item1'|translate}}</h4>
                    <p>{{'rooms.roomOne.item1Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bed"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item2'|translate}}</h4>
                    <p>{{'rooms.roomOne.item2Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bath"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item3'|translate}}</h4>
                    <p>{{'rooms.roomOne.item3Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tv"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item4'|translate}}</h4>
                    <p>{{'rooms.roomOne.item4Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-border-all"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-archway"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tshirt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item7'|translate}}</h4>
                    <p>{{'rooms.roomOne.item7Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-wifi"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item8'|translate}}</h4>
                    <p>{{'rooms.roomOne.item8Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-person-booth"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item9'|translate}}</h4>
                    <p>{{'rooms.roomOne.item9Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-key"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item10'|translate}}</h4>
                    <p>{{'rooms.roomOne.item10Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-fire-alt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item11'|translate}}</h4>
                    <p>{{'rooms.roomOne.item11Description'|translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </mat-tab>
  <mat-tab label="Si nos dejan">
    <section>
      <!--
        <div class="container">
          <div class="section-title">
            <h2>Room #1: Acá entre nos</h2>
            <p></p>
          </div>
        </div>-->

      <gallery *ngIf="loading2" [flexBorderSize]="20" [flexImageSize]="7" [galleryName]="'galleryRoom2'"
        [maxRowsPerPage]="50"></gallery>


      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="about-content">
            <h3>{{'rooms.description'|translate}}:</h3>
            <p></p>
            <div class=" about-inner">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-money-bill"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item1'|translate}}</h4>
                    <p>{{'rooms.roomOne.item1Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bed"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item2'|translate}}</h4>
                    <p>{{'rooms.roomOne.item2Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bath"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item3'|translate}}</h4>
                    <p>{{'rooms.roomOne.item3Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tv"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item4'|translate}}</h4>
                    <p>{{'rooms.roomOne.item4Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-border-all"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-archway"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tshirt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item7'|translate}}</h4>
                    <p>{{'rooms.roomOne.item7Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-wifi"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item8'|translate}}</h4>
                    <p>{{'rooms.roomOne.item8Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-person-booth"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item9'|translate}}</h4>
                    <p>{{'rooms.roomOne.item9Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-key"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item10'|translate}}</h4>
                    <p>{{'rooms.roomOne.item10Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-fire-alt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item11'|translate}}</h4>
                    <p>{{'rooms.roomOne.item11Description'|translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </mat-tab>
  <mat-tab label="Sabor a mí">
    <section>
      <gallery *ngIf="loading3" [flexBorderSize]="20" [flexImageSize]="7" [galleryName]="'galleryRoom3'"
        [maxRowsPerPage]="50"></gallery>


      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="about-content">
            <h3>{{'rooms.description'|translate}}:</h3>
            <p></p>
            <div class=" about-inner">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-money-bill"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item1'|translate}}</h4>
                    <p>{{'rooms.roomOne.item1Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bed"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item2'|translate}}</h4>
                    <p>{{'rooms.roomOne.item2Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-bath"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item3'|translate}}</h4>
                    <p>{{'rooms.roomOne.item3Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tv"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item4'|translate}}</h4>
                    <p>{{'rooms.roomOne.item4Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-border-all"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-archway"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item5'|translate}}</h4>
                    <p>{{'rooms.roomOne.item5Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-tshirt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item7'|translate}}</h4>
                    <p>{{'rooms.roomOne.item7Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-wifi"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item8'|translate}}</h4>
                    <p>{{'rooms.roomOne.item8Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-person-booth"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item9'|translate}}</h4>
                    <p>{{'rooms.roomOne.item9Description'|translate}}</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-key"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item10'|translate}}</h4>
                    <p>{{'rooms.roomOne.item10Description'|translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="single-inner-box">
                    <div class="icon">
                      <i class="fas fa-fire-alt"></i>
                    </div>
                    <h4>{{'rooms.roomOne.item11'|translate}}</h4>
                    <p>{{'rooms.roomOne.item11Description'|translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </mat-tab>
</mat-tab-group>
