<p>Reservation</p>

<mat-horizontal-stepper linear #stepper>
  <mat-step [stepControl]="" [editable]="true">
    <form [formGroup]="">
      <ng-template matStepLabel>Select the date</ng-template>

      <mat-form-field>
        <mat-date-range-input [rangePicker]="picker" min="minDate">
          <input matStartDate placeholder="Check in" />
          <input matEndDate placeholder="Check out" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <ul>
        <li>
          <input type="checkbox" id="cb1" />
          <label for="cb1"><img src="assets/img/gallery/galleryRoom1/preview_xxs/room1_3.jpeg" /></label>
        </li>
        <li>
          <input type="checkbox" id="cb2" />
          <label for="cb2"><img src="assets/img/gallery/galleryRoom2/preview_xxs/IMG_5935.jpg" /></label>
        </li>
        <li>
          <input type="checkbox" id="cb3" />
          <label for="cb3"><img src="assets/img/gallery/galleryRoom3/preview_xxs/room3_6.jpeg" /></label>
        </li>
      </ul>

      <mat-form-field>
        <mat-label>Adultos</mat-label>
        <input matInput type="number" placeholder="Adultos" min="1" max="2" />
        <span matSuffix></span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Niños</mat-label>
        <input matInput type="number" placeholder="Niños" min="0" max="4" />
        <span matSuffix></span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Bebés</mat-label>
        <input matInput type="number" placeholder="Bebés" min="0" max="4" />
        <span matSuffix></span>
      </mat-form-field>

      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="" [editable]="true">
    <form [formGroup]="forma">
      <ng-template matStepLabel>Fill out your data</ng-template>

      <div class="example-container">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput type="text" formControlName="nombre" />
          <mat-error *ngIf="nombreNoValido">
            Nombre is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Apellido</mat-label>
          <input matInput type="text" formControlName="apellido" />
          <mat-error *ngIf="apellidoNoValido">
            Apellido is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Correo</mat-label>
          <input matInput type="text" formControlName="correo" />
          <mat-error *ngIf="correoNoValido">
            Correo is <strong>not valid</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Teléfono</mat-label>
          <input matInput required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>País</mat-label>
          <input matInput required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Dirección</mat-label>
          <input matInput required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ciudad</mat-label>
          <input matInput required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Estado / Provincia</mat-label>
          <input matInput required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Código Postal</mat-label>
          <input matInput required />
        </mat-form-field>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Select your payment method</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
