import { Component, OnInit } from '@angular/core';

import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {



  slides = [
    { 'image': '../assets/img/slider/slider1.jpg' },
    { 'image': '../assets/img/slider/slider2.jpg' },
    { 'image': '../assets/img/slider/slider3.jpg' },
    { 'image': '../assets/img/slider/slider4.jpg' },
    { 'image': '../assets/img/slider/slider5.jpg' }];



  constructor() {




  }

  ngOnInit(): void {



  }

}
