<header>
  <mdb-navbar SideClass="navbar navbar-expand-sm navbar-dark indigo">
    <mdb-navbar-brand>
      <div class="navbar-brand">

        <a href="#">
          <div id="logo">Hotel Amor Eterno</div>
          <span class="tagline">Pátzcuaro, Michoacán México</span>
          <img src="../assets/img/monarch-butterfly1.png" width="30" height="30" class="d-inline-block align-top"
            alt="" />
        </a>

      </div>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/home']" class="nav-link">{{'menu.home'| translate}}
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/about']" class="nav-link">{{'menu.about'| translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/rooms']" class="nav-link">{{'menu.rooms'| translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/contact']" class="nav-link">{{'menu.contact'| translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a target="_blank" href="https://www.airbnb.com/h/aepatzcuaro"
            class="nav-link">{{'menu.reserve'| translate}}</a>
        </li>

        <!--
        <li class="flags">
          <a class="flag" (click)="lang='en'">
            <img src="../assets/img/usa-flag.png" alt="English">
          </a>
          <a class="flag" (click)="lang='es'">
            <img src="../assets/img/mexico-flag2.png" alt="Spanish">
          </a>

        </li>-->

      </ul>
    </links>
  </mdb-navbar>
</header>

<div class="language-switcher">
  <ul>
    <li class="es"><a role="button" (click)="func('es')" [style.opacity]="lang=='es' ? 1 : .3">es</a>
    </li>
    <li class="en"><a role="button" (click)="func('en')" [style.opacity]="lang == 'en' ?  1 : .3">en</a>
    </li>
  </ul>
</div>

<div id="column_left" class="col-xs-12 col-lg-3">
  <div class="position-fixed">

    <a href="https://www.airbnb.com/h/aepatzcuaro" target="_blank">{{'reserveHere'| translate}}
    </a>
  </div>
</div>

<section id="content">
  <router-outlet></router-outlet>
</section>

<footer>
  <div class="">
    <div class="row">
      <div class="col-sm footer-logo">
        <img class="seal" src="../assets/img/logo.png" />
      </div>
      <div class="col-sm footer-contact">
        <h3>{{'footer.contact'|translate}}</h3>
        <p>
          <i class="fas fa-map-marker-alt"></i>
          {{'footer.address'|translate}}
        </p>

        <p>
          <i class="fas fa-phone"></i>
          {{'footer.phone'|translate}}: +52 33 3302 0465</p>

        <p>
          <i class="fas fa-envelope"></i>
          Email:
          <a href="mailto:aepatzcuaro@gmail.com">aepatzcuaro@gmail.com</a>
        </p>
      </div>
      <div class="col-sm nav-footer footer-links">
        <h3>{{'footer.links'|translate}}</h3>

        <p>
          <a class="scroll-link" [routerLink]="['/home']">{{'menu.home'| translate}}</a>
        </p>
        <p>
          <a class="scroll-link" [routerLink]="['/about']">{{'menu.about'| translate}}</a>
        </p>
        <p>
          <a class="scroll-link" [routerLink]="['/rooms']">{{'menu.rooms'| translate}}</a>
        </p>
        <p>
          <a class="scroll-link" [routerLink]="['/contact']">{{'menu.contact'| translate}}</a>
        </p>
        <p>
          <a class="scroll-link" target="_blank"
            href="https://www.airbnb.com/h/aepatzcuaro">{{'menu.reserve'| translate}}</a>
        </p>
        <p>
          <a class="scroll-link" [routerLink]="['/hotel-policies']">{{'menu.policies'| translate}}</a>
        </p>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-sm copyright">&copy; Hotel Amor Eterno 2021</div>

      <div class="col-sm footer-social">
        <a target="_blank" href="http://www.facebook.com/aepatzcuaro">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a target="_blank" href="http://www.instragram.com/aepatzcuaro">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </div>
</footer>

<app-scroll-to-top></app-scroll-to-top>
