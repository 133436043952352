<section class="about">
  <h1 class="font-cursive">Amor Eterno</h1>
  <h3>Hotel Boutique</h3>

  <p>
    {{"about.hotel"|translate}}
  </p>
</section>

<section class="about">
  <h1 class="font-cursive">Pátzcuaro, Michoacán</h1>

  <p>
    {{"about.patzcuaro"|translate}}
  </p>

  <gallery [flexBorderSize]="25" [flexImageSize]="12" [galleryName]="'galleryPatzcuaro'" [maxRowsPerPage]="50">
  </gallery>

</section>


<section class="about">
  <h1 class="font-cursive"> {{"about.visit"|translate}}</h1>

  <p>
    {{"about.visitMessage"|translate}}
  </p>





  <div class="card-deck text-center">
    <div class="card">

      <gallery [flexBorderSize]="1" [flexImageSize]="38" [galleryName]="'imgJanitzio'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Lago de Pátzcuaro y sus islas</h5>

        <button class="button" mdbBtn color="link" (click)="openXl(content,'islands')">Ver más</button>

      </div>
    </div>
    <div class="card">

      <gallery [flexBorderSize]="1" [flexImageSize]="38" [galleryName]="'imgTzintzuntzan'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Tzintzuntzan e Ihuatzio</h5>

        <button class="button" mdbBtn color="link" (click)="openXl(content,'tzintzuntzan')">Ver más</button>
      </div>
    </div>
  </div>





  <div class="card-deck text-center">
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="45" [galleryName]="'imgQuiroga'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Quiroga</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'quiroga')">Ver más</button>
      </div>
    </div>
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="45" [galleryName]="'imgSantaClara'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Santa Clara del Cobre</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'santaClara')">Ver más</button>
      </div>
    </div>
  </div>




  <div class="card-deck text-center">
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="38" [galleryName]="'imgTacambaro'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Tacámbaro</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'tacambaro')">Ver más</button>
      </div>
    </div>
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="38" [galleryName]="'imgZirahuen'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Zirahuén</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'zirahuen')">Ver más</button>
      </div>
    </div>
  </div>





  <div class="card-deck text-center">
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="45" [galleryName]="'imgUruapan'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Uruapan</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'uruapan')">Ver más</button>
      </div>
    </div>
    <div class="card">
      <gallery [flexBorderSize]="1" [flexImageSize]="45" [galleryName]="'imgMorelia'" [maxRowsPerPage]="2">
      </gallery>
      <div class="card-body">
        <h5 class="card-title">Morelia</h5>
        <button class="button" mdbBtn color="link" (click)="openXl(content,'morelia')">Ver más</button>
      </div>
    </div>
  </div>



</section>



<ng-template #modal_1>
  <!-- Modal -->
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header"> <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> &times;
          </button>
          <h4 class="modal-title" id="myModalLabel"> This Modal title
          </h4>
        </div>
        <div class="modal-body"> Add some text here </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="closeDialog()">Close </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal -->
  </div>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header" tabindex="-1">
    <h1 class="modal-title">{{title}}</h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{ 'about.' + description | translate }}
    </p>

    <div class="container">
      <iframe class="map responsive-iframe" [src]="mapa" width="600" height="450" style="border:0;" allowfullscreen=""
        loading="lazy"></iframe>
    </div>

  </div>


</ng-template>
