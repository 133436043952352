<div id="pc-carousel">
  <mat-carousel timings="200ms ease-in" [autoplay]="true" interval="5000" color="white" maxWidth="auto" maxHeight="auto"
    proportion="40" slides="5" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
    [useMouseWheel]="false">


    <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
      overlayColor="#00080d15" [hideOverlay]="false"></mat-carousel-slide>
  </mat-carousel>

</div>


<div id="mobile-carousel">
  <mat-carousel timings="200ms ease-in" [autoplay]="true" interval="5000" color="white" maxWidth="auto" maxHeight="auto"
    proportion="190" slides="5" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
    [useMouseWheel]="false">

    <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
      overlayColor="#00080d15" [hideOverlay]="false"></mat-carousel-slide>
  </mat-carousel>
</div>

<section>
  <div class="section-2-container section-container section-container-gray-bg">
    <div class="">
      <div class="row">
        <div class="col-sm section-2 section-description wow fadeIn an
        imated" style="visibility: visible; animation-name: fadeIn">

          <p class="medium-paragraph ">
            {{'home.welcomeHello'|translate}}

          </p>
          <h2 class="font-cursive">{{'home.welcomeMessage'|translate}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm section-2-box wow fadeInLeft animated"
          style="visibility: visible; animation-name: fadeInLeft">

          <p>
            {{'home.welcomeBody'|translate}}
          </p>

        </div>
      </div>
    </div>
  </div>

  <div class="section-1-container section-container">
    <div class="col section-1 section-description wow fadeIn animated"
      style="visibility: visible; animation-name: fadeIn">
      <h2 class="font-cursive">
        {{'home.gallery'|translate}}</h2>
    </div>
    <gallery [flexBorderSize]="1" [flexImageSize]="28" [galleryName]="'galleryMain'" [maxRowsPerPage]="50"></gallery>

  </div>




</section>
